import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import ButtonGroup from "../molecules/ButtonGroup"

const InfoboxBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="false">
      <div className="px-4 py-4 bg-gray-100 border-4 lg:p-16 lg:pt-12 border-primary">
        <div className="flex flex-row flex-wrap">
          {/* {block.icon?.icon?.length > 0 && (
            <div className="flex">
              <span className="h-32 pl-4 pr-8 mt-3 lg:pl-0 lg:pr-16">
                Hier Icon einfügen?
              </span>
            </div>
          )} */}
          <div className="flex-1">
            <HeadlineEnhanced
              kicker={block.kicker}
              headline={block.headline}
              subheadline={block.subheadline}
            />
            <Richtext
              flow={block.flow}
              text={block.text}
              className={`pt-8 ${
                block.text_size === "text-lg" ? block.text_size : ""
              }`}
            />
            {block.buttons?.length > 0 && (
              <ButtonGroup className="mt-8" buttons={block.buttons} />
            )}
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default InfoboxBlock
